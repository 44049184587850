import * as React from "react"

import { _h1, _description } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { _linkButton, _linkBlockButton } from "../shared/buttons/"
import { Row, Col } from "react-flexbox-grid"
import styled from "styled-components"

interface PostContentProps {
    content: JSX.Element
}

const _ContentWrapper = styled.div`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--slate-grey);
    padding-bottom: 2em;

    p.block-img {
        text-align: center;
    }

    img {
        max-width: 100%;
        margin: auto;
    }

    a {
        color: #4340de;
    }
`

export const PostContent: React.FC<PostContentProps> = ({ content }) => {
    return (
        <Row>
            <Col lgOffset={2} mdOffset={1} lg={8} md={10} sm={12}>
                <_ContentWrapper>{content}</_ContentWrapper>
            </Col>
        </Row>
    )
}
