import styled from "styled-components"
import { TABLET_SCREEN_WIDTH } from "../../utils/responsiveHelper"

export const _postHeadling = styled.div`
    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--slate-grey);
    text-align: left;
`

export const _socialShare = styled.div`
    align-items: center;
    display: flex;
    justify-content: flex-end;

    button {
        margin-left: 1em;
    }

    &.sticky {
        margin-top: 100px;
        z-index: 3;
    }

    @media only screen and (max-width: ${TABLET_SCREEN_WIDTH - 1}px) {
        justify-content: space-between;
        border-top: 1px solid #ccc;
        margin-top: 1em;
        padding: 1em 0;
    }
`

export const _tagsContainer = styled.div`
    display: inline-block;
    margin-left: 15px;

    @media only screen and (max-width: ${TABLET_SCREEN_WIDTH - 1}px) {
        display: block;
        margin: 10px 0;
    }
`

export const _svgImage = styled.img`
    position: absolute;
    top: -100px;
    right: 95%;
    z-index: -1;
`

export const _socialLinkContainer = styled.div`
    height: 40px;

    &.sticky {
        object-fit: contain;
        opacity: 0.87;
        border-radius: 23px;
        box-shadow: 0 0 4px 0 rgba(216, 216, 216, 0.5);
        border: solid 1px #f9f9f9;
        background-color: #ffffff;
      }
    }

    button {
        margin: 0;
        height: 40px;
    }
`
