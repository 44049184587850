import * as React from "react"

import { _h1, _description } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { _linkButton, _linkBlockButton } from "../shared/buttons/"
import { Row, Col } from "react-flexbox-grid"
import { _processLink } from "../../components/shared/links.styles"
import { ArrowLeft, ArrowRight } from "../shared/Arrow"
import { _customAlignCol } from "../shared"

interface PostNavigationProps {
    prevPost: string
    nextPost: string
}

export const PostNavigation: React.FC<PostNavigationProps> = ({ prevPost, nextPost }) => {
    return (
        <Row center="xs">
            <Col lg={8} md={12}>
                <Row>
                    <_customAlignCol sm={6} align="left">
                        {prevPost && (
                            <_processLink to={prevPost}>
                                <ArrowLeft /> Previous post
                            </_processLink>
                        )}
                    </_customAlignCol>

                    <_customAlignCol sm={6} align="right">
                        {nextPost && (
                            <_processLink to={nextPost}>
                                Next post <ArrowRight />
                            </_processLink>
                        )}
                    </_customAlignCol>
                </Row>
            </Col>
        </Row>
    )
}
