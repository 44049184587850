import * as React from "react"
import styled from "styled-components"
import Arrow from "../../images/arrow-left.svg"

const _arrowImg = styled.img`
    height: 19px;
    display: inline-block;
    margin-bottom: -4px;
    padding-right: 5px;
`

const _arrowImgRight = styled(_arrowImg)`
    transform: rotate(180deg);
`

export const ArrowLeft: React.FC = () => {
    return <_arrowImg src={Arrow} />
}

export const ArrowRight: React.FC = () => {
    return <_arrowImgRight src={Arrow} />
}
