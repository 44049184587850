import * as React from "react"

import { AppStateContext } from "../../utils/PageWrapper"
import { _h1, _description } from "../shared/headline.styles"
import { _Blockquote } from "../shared/blockquote.styles"
import { _customAlignCol } from "../shared"
import { _linkButton, _linkBlockButton } from "../shared/buttons/"
import { Row, Col } from "react-flexbox-grid"
import { Sticky } from "react-sticky"
import { dateDecorator } from "../../utils"
import { Tag } from "../shared/tags"
import { _postHeadling, _socialShare, _tagsContainer, _svgImage, _socialLinkContainer } from "./hero.styles"

import Hexagon4 from "../../images/v2/hexagon-4.svg"

import {
    FacebookShareButton,
    TwitterShareButton,
    FacebookIcon,
    TwitterIcon,
    LinkedinShareButton,
    LinkedinIcon
} from "react-share"

interface HeroProps {
    title: string
    description: string | null
    author: string
    date: string
    tags: string[]
}

interface SocialMediaButtonsProps {
    url: string
    text: string
    className?: string
}

export const SocialMediaButtons: React.FC<SocialMediaButtonsProps> = ({ url, text, className }) => {
    return (
        <_socialLinkContainer className={className}>
            <FacebookShareButton url={url} quote={text}>
                <FacebookIcon size={40} round={true} bgStyle={{ fill: "transparent" }} iconFillColor="#000" />
            </FacebookShareButton>

            <TwitterShareButton url={url} title={text} via="radicleinsights">
                <TwitterIcon size={40} round={true} bgStyle={{ fill: "transparent" }} iconFillColor="#000" />
            </TwitterShareButton>

            <LinkedinShareButton url={url} title={text}>
                <LinkedinIcon size={40} round={true} bgStyle={{ fill: "transparent" }} iconFillColor="#000" />
            </LinkedinShareButton>
        </_socialLinkContainer>
    )
}

export const Hero: React.FC<HeroProps> = ({ title, description, author, date, tags }) => {
    const appState = React.useContext(AppStateContext)

    if (!appState.isReady) return null
    return (
        <Row center="xs">
            {!appState.isMobile && <_svgImage src={Hexagon4} />}
            <Col lg={8} md={12}>
                <_description>
                    {description}
                    <_tagsContainer>
                        {tags?.map(tag => (
                            <Tag key={tag} tag={tag} href={`/insights?tag=${tag}`} />
                        ))}
                    </_tagsContainer>
                </_description>
                <_h1 style={{ marginTop: 0.2 + "em", marginBottom: 0.5 + "em" }}>{title}</_h1>
            </Col>

            <Col lg={7} md={12}>
                <_postHeadling>{dateDecorator(date)}</_postHeadling>
            </Col>

            <Col lg={7} md={12}>
                <Row middle="xs">
                    <_customAlignCol align="left" md={6}>
                        <_postHeadling>By {author}</_postHeadling>
                    </_customAlignCol>

                    <_customAlignCol align="right" md={6}>
                        <_postHeadling>
                            {appState.isMobile && (
                                <_socialShare>
                                    <SocialMediaButtons url={window.location.href} text={title} />
                                </_socialShare>
                            )}

                            {!appState.isMobile && (
                                <Sticky topOffset={200}>
                                    {({ style, isSticky }) => (
                                        <_socialShare style={{ ...style }} className={isSticky ? "sticky" : ""}>
                                            <SocialMediaButtons
                                                className={isSticky ? "sticky" : ""}
                                                url={window.location.href}
                                                text={title}
                                            />
                                        </_socialShare>
                                    )}
                                </Sticky>
                            )}
                        </_postHeadling>
                    </_customAlignCol>
                </Row>
            </Col>
        </Row>
    )
}
