import * as React from "react"

import { _h1, _h2 } from "../../../shared/headline.styles"
import { _Blockquote } from "../../../shared/blockquote.styles"
import { _buttonBlock } from "../../../shared/buttons"
import { Input } from "../../../shared/inputs"
import { _formHint, _errorBox } from "../../../shared/form/form.styles"
import styled from "styled-components"

interface FormProps {
    isSubmitting: boolean
    errors: any
    handleChange: any
    handleSubmit: any
    isValid: boolean
    submitCount: number
    values: any
    status?: string
}

const _form = styled.div`
    width: 100%;
`
export const MobileForm = (props: FormProps) => {
    const { isSubmitting, errors, handleChange, handleSubmit, values, status } = props

    return (
        <_form>
            <Input
                label="Email"
                type="email"
                name="email"
                placeholder="Your email"
                onChange={handleChange}
                errors={errors.email}
                value={values.email}
            />

            <_buttonBlock marginTop={2} onClick={handleSubmit} type="submit">
                {isSubmitting ? "Loading..." : "Contact us"}
            </_buttonBlock>

            {status === "success" && (
                <_Blockquote color={"var(--cool-green)"} style={{ textAlign: "center" }}>
                    Thanks for subscription!
                </_Blockquote>
            )}
        </_form>
    )
}
