const postUrl = `https://api.hsforms.com/submissions/v3/integration/submit/${process.env.GATSBY_HUBSPOT_PORTAL_ID}/${process.env.GATSBY_HUBSPOT_NEWSLETTER_FORM_ID}`

import { FormikHelpers as FormikActions } from "formik"
import { postRequest } from "../../../../utils/fetchRequest"

interface Values {
    email: string
}

export const onSubmit = (values: Values, actions: FormikActions<Values>): void => {
    const body = {
        submittedAt: Date.now(),
        fields: [
            {
                name: "email",
                value: values.email
            }
        ]
    }

    postRequest(postUrl, body)
        .then(() => {
            actions.setSubmitting(false)
            actions.setStatus("success")
        })
        .catch(() => {
            actions.setStatus("fail")
        })
}
