import * as React from "react"
import { Layout, _Section, HeadComponent } from "../components/shared"
import { _processLink } from "../components/shared/links.styles"
import { PageWrapper } from "../utils/PageWrapper"
import { graphql } from "gatsby"
import { Row, Col } from "react-flexbox-grid"
import { _h1 } from "../components/shared/headline.styles"
import { _Blockquote } from "../components/shared/blockquote.styles"
import { RichText } from "prismic-reactjs"
import { prismicGetText, extractPrismicNode } from "../utils/prismicHelper"
import { Hero, PostContent, PostNavigation } from "../components/blogPost/"
import { ArrowLeft } from "../components/shared/Arrow"
import { truncate } from "../utils/responsiveHelper"
import { StickyContainer } from "react-sticky"
import { StringMap } from "../utils/"
import { BlogPostNewsletterForm } from "../components/blogPost/BlogPostNewsletterForm"

export const query = graphql`
    query PageQuery($uid: String) {
        prismic {
            allBlog_posts(uid: $uid) {
                edges {
                    node {
                        _meta {
                            tags
                        }
                        blog_author
                        blog_cover_photo
                        blog_date
                        blog_post
                        title_for_email_subscription
                        description_for_email_subscription
                        description_below_the_email_input_subscription
                        button_label_for_email_subscription
                        display_the_email_subscription_component
                        blog_post_readmore
                        blog_title
                        blogpost_category {
                            ... on PRISMIC_Insight_insights {
                                description_insights
                            }
                            ... on PRISMIC_Insight_innovators {
                                description_innovators
                            }
                            ... on PRISMIC_Insight_research {
                                description_insight
                            }
                            ... on PRISMIC_Insight_news {
                                description_news
                            }
                            ... on PRISMIC_Insight_data {
                                description_data
                            }
                            ... on PRISMIC_Insight_disruptive {
                                description_disruptive_voices
                              }
                        }
                    }
                }
            }
        }
    }
`

interface BlogPostProps {
    location: any
    data: {
        prismic: {
            allBlog_posts: {
                edges: any
            }
        }
    }
}

const BlogPost: React.FC<BlogPostProps> = ({ location, data }) => {
    const [showNewsletterBlock, setShowNewsletterBlock] = React.useState(true);
    const closeNewsletterBlock = () => {
        setShowNewsletterBlock(false)
    }

    const doc = extractPrismicNode(data, "allBlog_posts")
    if (!doc) return null

    let prevUrl = null

    if (location.state && location.state.prevPath) {
        prevUrl = location.state.prevPath
    }

    const tags = doc._meta && doc._meta.tags
    // FIXME: typeesss
    const categoryDescription = (category: any) => {
        if (!category) return null
        const key = Object.keys(category)[0]

        const keyName: StringMap = {
            description_innovators: "Breaking Through",
            description_insight: "Research",
            description_insights: "Insights",
            description_news: "From the Lab",
            description_data: "Data",
            description_disruptive_voices: "Disruptive voices"
        }

        return keyName[key]
    }

    const postTitleTruncated = truncate(prismicGetText(doc.blog_title), 40)
    const postContent = (
        <div>
            <RichText render={doc.blog_post} />
            <div id="readmore" style={{ position: "relative", top: "-100px" }} />
        </div>
    )
    const postContentBottom = (
        <div>
            <RichText render={doc.blog_post_readmore} />
        </div>
    )

    return (
        <>
            <HeadComponent title={`${postTitleTruncated} | Radicle`} />
            <PageWrapper>
                <Layout>
                    <_Section marginBottom={1} marginTopSm={3} marginBottomSm={1} style={{ zIndex: 10 }}>
                        <Row>
                            {prevUrl && (
                                <Col xs={12}>
                                    <_processLink to={prevUrl}>
                                        <ArrowLeft /> Back
                                    </_processLink>
                                </Col>
                            )}
                        </Row>
                    </_Section>

                    <StickyContainer>
                        <_Section marginTop={-2.4} marginTopSm={1}>
                            <Hero
                                description={categoryDescription(doc.blogpost_category)}
                                title={prismicGetText(doc.blog_title)}
                                author={prismicGetText(doc.blog_author)}
                                date={doc.blog_date}
                                tags={tags}
                            />
                        </_Section>

                        <_Section>
                            <PostContent content={postContent} />
                            {
                                showNewsletterBlock
                                && doc.display_the_email_subscription_component
                                && <BlogPostNewsletterForm
                                    title_for_email_subscription={prismicGetText(doc.title_for_email_subscription)}
                                    description_for_email_subscription={prismicGetText(doc.description_for_email_subscription)} description_below_the_email_input_subscription={prismicGetText(doc.description_below_the_email_input_subscription)} button_label_for_email_subscription={prismicGetText(doc.button_label_for_email_subscription)}
                                    closeBlock={closeNewsletterBlock}
                                />}
                            <PostContent content={postContentBottom} />
                        </_Section>
                    </StickyContainer>

                    <_Section>
                        <PostNavigation prevPost={""} nextPost={""} />
                    </_Section>
                </Layout>
            </PageWrapper>
        </>
    )
}

export default BlogPost
