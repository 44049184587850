import * as React from "react"

import styled from "styled-components"
import { validationSchema, onSubmit } from "./insightSections/InsightNewsletterForm"
import { Formik } from "formik"
import { Input } from "../shared/inputs"
import { _button } from "../shared/buttons/button.styles"
import { Col, Row } from "react-flexbox-grid"
import { MOBILE_SCREEN_WIDTH, TABLET_SCREEN_WIDTH, DESKTOP_SCREEN_WIDTH, LARGE_DESKTOP_SCREEN_WIDTH } from "../../utils/responsiveHelper"
import { AppStateContext } from "../../utils/PageWrapper"

import OrangeCircle from "../../images/v2/orange.svg"
import BlueCircle from "../../images/v2/blue.svg"
import CloseIcon from "../../images/close.svg"

interface BlogPostNewsletterFormProps {
  title_for_email_subscription: string | null
  description_for_email_subscription: string | null
  description_below_the_email_input_subscription: string | null
  button_label_for_email_subscription: string | null
  closeBlock: () => void
}

interface FormProps extends BlogPostNewsletterFormProps {
  isSubmitting: boolean
  errors: any
  handleChange: any
  handleSubmit: any
  isValid: boolean
  submitCount: number
  values: any
  status?: string
}

const _form = styled.div`
  * {
    font-family: Nunito;
    text-align: center;
  }

  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1), 0 0 2px 0 rgba(0, 0, 0, 0.1);
  background-color: var(--white);
  padding: 0 106px 32px;
  border-radius: 8px;
  margin-bottom: 50px;

  @media only screen and (max-width: ${MOBILE_SCREEN_WIDTH}px) {
    padding: 0 15px 28px;
    border-radius: 0;
    width: 100vw;
    margin-left: -1.5em;
    margin-right: -1.5em;

    * {
      text-align: left;
    }
  }
`

const _text = styled.p`
  font-family: Nunito;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: var(--slate-grey);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 0;

  strong {
    color: var(--dark);
    font-size: 18px;
    line-height: 1.56;
    margin-bottom: 16px;
  }

  @media only screen and (max-width: ${TABLET_SCREEN_WIDTH}px) {
    align-items: flex-start;
  }
`;

const _headingText = styled(_text)`
  font-size: 18px;
  line-height: 1.56;
  font-weight: bold;
  margin: 0;
  color: var(--dark);
`;

const _inputContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: baseline;
  margin: -16px 0;

  @media only screen and (max-width: ${TABLET_SCREEN_WIDTH}px) {
    flex-direction: column;
    margin: unset;
  }

  & > div:first-child {
    flex: 1;
    margin-right: 14px;

    @media only screen and (max-width: ${TABLET_SCREEN_WIDTH}px) {
      width: 100%;
      margin-right: 0;
    }
  }
`;

const _orangeCircle = styled.img`
  position: absolute;
  z-index: -1;
  right: 0;
  transform: translate(-32%, -38%); 
  width: 383px;
  height: 326px;

  @media only screen and (max-width: 991px) {
    transform: translate(16%, -38%);
  }

  @media only screen and (max-width: ${MOBILE_SCREEN_WIDTH}px) {
    display: none;
  }
`;

const _blueCircle = styled.img`
  position: absolute;
  z-index: -1;
  right: 0;
  transform: translate(-257%, 32%);
  width: 287px;
  height: 241px;


  @media only screen and (max-width: ${LARGE_DESKTOP_SCREEN_WIDTH}px) {
    transform: translate(-190%, 32%);
  }

  @media only screen and (max-width: ${DESKTOP_SCREEN_WIDTH}px) {
    transform: translate(-164%, 19%);
  }

  @media only screen and (max-width: ${TABLET_SCREEN_WIDTH}px) {
    transform: translate(-100%, 56%);
  }

  @media only screen and (max-width: ${MOBILE_SCREEN_WIDTH}px) {
    display: none;
  }
`;

const _closeIcon = styled.div`
  height: 22px;
  width: 100%;
  text-align: right;

  @media only screen and (max-width: ${MOBILE_SCREEN_WIDTH}px) {
    height: 28px;
  }

  img {
    margin: 0.5em;
    position: relative;
    right: -106px;
    opacity: .5;
    cursor: pointer;

    @media only screen and (max-width: ${MOBILE_SCREEN_WIDTH}px) {
      right: unset;
    }
  }
`;

const NewsletterForm = (props: FormProps) => {
  const { isSubmitting, errors, handleChange, handleSubmit, values, status, title_for_email_subscription, description_for_email_subscription, description_below_the_email_input_subscription, button_label_for_email_subscription, closeBlock } = props

  const appState = React.useContext(AppStateContext)

  return (
    <>
      <_orangeCircle src={OrangeCircle} alt="" />
      <_blueCircle src={BlueCircle} alt="" />
      <_form>
        <_closeIcon onClick={closeBlock}>
          <img src={CloseIcon} alt="Close" />
        </_closeIcon>
        {title_for_email_subscription && <_headingText>{title_for_email_subscription}</_headingText>}
        {description_for_email_subscription && <_text>{description_for_email_subscription}</_text>}
        <_inputContainer>
          <Input
            type="email"
            name="email"
            placeholder="Your email"
            onChange={handleChange}
            errors={errors.email}
            value={values.email}
          />

          <_button marginTop={appState.isMobile ? 0 : 2} onClick={handleSubmit} type="submit">
            {isSubmitting ? "Loading..." : button_label_for_email_subscription ? button_label_for_email_subscription : 'Subscribe'}
          </_button>
        </_inputContainer>

        {description_below_the_email_input_subscription && <_text>{description_below_the_email_input_subscription}</_text>}

        {status === "success" && (
          <_text>
            Thanks for subscription!
          </_text>
        )}
      </_form>
    </>
  )
}

export const BlogPostNewsletterForm: React.FC<BlogPostNewsletterFormProps> = (props) => {
  return (
    <Row>
      <Col lgOffset={2} lg={8} md={12} sm={12}>
        <Formik
          render={formikProps => <NewsletterForm {...formikProps} {...props} />}
          validationSchema={validationSchema}
          initialValues={{ email: '' }}
          onSubmit={onSubmit}
        />
      </Col>
    </Row>
  )
}
