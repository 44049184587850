import * as React from "react"

import { _h1, _description } from "../../../shared/headline.styles"
import { _Blockquote } from "../../../shared/blockquote.styles"
import { _linkButton, _linkBlockButton, _alternativeButton } from "../../../shared/buttons/"
import styled from "styled-components"

export const _EmailInput = styled.input.attrs(props => ({
    type: "text",
    size: props.size || "0.7em"
}))`
    border: none;
    font-size: 1em;
    border-radius: 4px;
    height: 48px;
    width: 300px;
    background-blend-mode: multiply;
    background-image: linear-gradient(to bottom, var(--white), var(--white));
    margin: 0;
    margin-top: 0.5em;
    padding: ${props => props.size};

    font-family: Nunito;
    font-size: 16px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: 0.1px;
    color: var(--slate-grey);
`

export const _formContainer = styled.div`
    position: relative;
    width: 100%;
    display: flex;

    input {
        margin-top: 0;
    }
`

interface FlashFormProps {
    isSubmitting: boolean
    handleChange: any
    handleSubmit: any
    values: any
    status?: string
    setIsSubscribed: Function
}

export const FlashForm = (props: FlashFormProps) => {
    const { handleChange, handleSubmit, values, status, isSubmitting, setIsSubscribed } = props // Formik sth

    if (status === "success") {
        setIsSubscribed(true)
    }

    return (
        <>
            <form>
                <_formContainer>
                    <_EmailInput
                        type="text"
                        name="email"
                        placeholder="Your email"
                        onChange={handleChange}
                        value={values.email}
                    />

                    <_alternativeButton onClick={handleSubmit} type="submit" marginLeft={2}>
                        {isSubmitting ? "Loading..." : "Subscribe"}
                    </_alternativeButton>
                </_formContainer>
            </form>
        </>
    )
}
